<template>
  <div id="page--login">
    <div class="login__left flex items-center">
      <div class="left__content">
        <Logo class="login__logo" />
        <p class="text-body font-bold tagline tagline__text">
          “Above all else, show the data”
        </p>
        <p class="text-muted tagline tagline__author">
          - Edward Rolf Tufte
        </p>
      </div>
    </div>
    <div
      class="login__right flex items-center"
      :style="
        `background-image: url('${require('@/assets/images/pages/login/abstract.svg')}')`
      "
    >
      <form class="right__card" autocomplete="off" @submit.prevent="sendForm">
        <Logo height="58" width="180" class="right__logo" color="white" />
        <h1 class="right__title font-bold text-white">Login</h1>
        <p class="right__desc text-xs text-white">
          Welcome back, please login to your account
        </p>
        <hr class="right__hr">
        <div class="right__email">
          <feather-icon
            icon="MailIcon"
            size="25"
            class="input__icon color-primary"
          />
          <input
            v-model="userEmail"
            class="right__input"
            type="email"
            placeholder="Email"
            required
          >
        </div>
        <div class="right__forgot-pass flex justify-between items-center">
          <b-form-checkbox
            id="remember-me"
            v-model="rememberMe"
            name="checkbox-1"
          >
            <span class="text-xs text-white">{{ $t('Remember Me') }}</span>
          </b-form-checkbox>
          <a class="text-primary text-xs" :href="forgotPasswordLink">
            Forgot Password?
          </a>
        </div>
        <div class="right__actions flex justify-center">
          <button class="btn--login" type="submit">
            Login
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required, email } from '@validations'
import Logo from '@/components/shared/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import { stringify as toQuery } from 'query-string'
import pkceChallenge from 'pkce-challenge'
import loginBackground from '../../assets/images/pages/login/login-bg.png'

export default {
  components: {
    Logo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      rememberMe: false,
      // validation rules
      required,
      email,
      loading: false,
      loginBackground,

      code_verifier: '',
      code_challenge: '',
    }
  },
  computed: {
    forgotPasswordLink() {
      return `${process.env.VUE_APP_PROZESS_API_ENDPOINT}/password_reset`
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    sendForm() {
      const { code_verifier, code_challenge } = pkceChallenge()
      const { protocol, host } = window.location
      const frontendUrl = `${protocol}//${host}`
      location.href = `${
        this.$http.defaults.baseURL
      }/oauth2/authorize?${toQuery({
        rememberMe: this.rememberMe,
        response_type: 'code',
        client_id: 'frontend-client',
        scope: 'app.user',
        redirect_uri: `${frontendUrl}/oauth2-redirect`,
        code_challenge,
        code_challenge_method: 'S256',
        email: this.userEmail,
      })}`
      localStorage.setItem('code_verifier', code_verifier)
    },
    login() {
      this.loading = true
      useJwt
        .login({
          email: this.userEmail,
          password: this.password,
        })
        .then(({ data }) => {
          const { user } = data.data
          useJwt.setToken(data.data.access_token)
          localStorage.setItem('userData', JSON.stringify(user))
          this.$router.push({ name: 'dashboard' })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.font-bold {
  font-weight: 700;
}

.text-white {
  color: white;
}

.text-primary {
  color: #01a66f;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.text-xs {
  font-size: 12px;
}

.text-sm {
  font-size: 14px;
}

.text-base {
  font-size: 16px;
}

#page--login {
  display: flex;
  min-height: 100vh;

  .login__left {
    display: none;

    @media screen and (min-width: 1400px) {
      display: flex;
    }
  }

  .login__left,
  .login__right {
    flex: 1 0;

    justify-content: center;
  }

  .login__left {
    @media screen and (min-width: 1700px) {
      justify-content: flex-end;
    }

    .left__content {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (min-width: 1700px) {
        margin-right: 200px;
      }
    }
  }

  .login__right {
    background-color: #01a66f;
    background-size: cover;

    @media screen and (min-width: 1700px) {
      justify-content: flex-start;
    }

    .right__card {
      @media screen and (min-width: 1700px) {
        margin-left: 100px;
      }

      width: 100%;
      max-width: 640px;
      background: #131f26;
      border-radius: 40px;
      margin: 0 16px;
      padding: 40px 30px;

      @media screen and (min-width: 768px) {
        padding: 70px 60px;
      }

      .right__logo {
        height: 50px;
        margin-bottom: 32px;

        @media screen and (min-width: 768px) {
          height: 60px;
        }

        @media screen and (min-width: 1400px) {
          display: none;
        }
      }

      .right__title {
        margin-bottom: 12px;
      }

      .right__desc {
        margin-bottom: 20px;
      }

      .right__hr {
        border-color: #01a66f;
        border-width: 3px;
        margin-bottom: 44px;
      }

      .right__email {
        position: relative;
        margin-bottom: 32px;

        .input__icon {
          position: absolute;
          left: 10px;
          top: 10px;
        }

        .right__forgot-pass {
          margin-top: 16px;
        }

        .right__input {
          background: #2e3d46 0% 0% no-repeat padding-box;
          border-radius: 8px;
          color: #fff;
          border: 0;
          padding: 13px;
          padding-left: 45px;
          font-size: 1.2rem;
          width: 100%;
          &:focus {
            outline: none;
          }
        }
      }

      .right__actions {
        margin-top: 75px;

        .btn--login {
          border: none;
          padding: 12px 70px;
          border-radius: 8px;
          background-color: #01a66f;
          color: white;
          font-size: 15px;
          outline: none;
          &:hover {
            background-color: #00b679;
          }

          &:active {
            background-color: #018f60;
          }
        }
      }
    }
  }

  .login__logo {
    margin-bottom: 64px;
  }

  .tagline {
    &.tagline__text {
      font-size: 32px;
      margin-bottom: 32px;
    }

    &.tagline__author {
      font-size: 23px;
    }
  }
}
</style>
